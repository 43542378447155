<template>
  <validation-observer ref="simpleRules">
    <div class="row">
      <div class="col-12">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50"
          @click="$router.push({ name: 'admin-faq'})"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
        </b-button>
        <b-button
          class="btn btn-gradient-success mb-1 ml-25 mr-50"
          @click="Submit()"
        >
          <i class="far fa-save mr-50" /> {{ $t('saveData') }}
        </b-button>
        <div>
          <div class="card fixed-card">
            <div class="d-flex justify-content-between align-items-center px-2">
              <div>
                <br>
                <h3
                  class="font-weight-bolder"
                >
                  {{ $t('key-83') }}
                </h3>
              </div>
            </div>
            <hr>
            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                <span class="text-danger">*</span> {{ $t('question') }}
              </template>
              <validation-provider
                #default="{ errors }"
                name="product-type"
                rules="required"
              >
                <b-form-input
                  v-model="items.question"
                  :state="errors.length > 0 ? false:null"
                />
                <span class="text-danger">{{ errors[0] ? $t('key-82') : '' }}</span>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                <span class="text-danger">*</span> {{ $t('answer') }}
              </template>
              <!-- <b-form-textarea v-model="answer" /> -->
              <quill-editor
                ref="quillEditor"
                v-model="items.result"
                class="p-2"
                :options="editorOption"
              >
                <div
                  id="toolbar"
                  slot="toolbar"
                >
                  <span class="ql-formats">
                    <select class="ql-size" />
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                    <button class="ql-strike" />
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color" />
                    <select class="ql-background" />
                  </span>

                  <span class="ql-formats">
                    <button
                      class="ql-list"
                      value="ordered"
                    />
                    <button
                      class="ql-list"
                      value="bullet"
                    />
                    <button
                      class="ql-indent"
                      value="-1"
                    />
                    <button
                      class="ql-indent"
                      value="+1"
                    />
                  </span>
                  <span class="ql-formats">

                    <select class="ql-align" />
                    <b-dropdown
                      text="😃"
                      variant="light"
                    >
                      <b-dropdown-item>
                        <VEmojiPicker
                          @select="selectEmoji"
                        />
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>

                </div>
              </quill-editor>
            </b-form-group>
            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                {{ $t('activate') }}
              </template>

              <b-form-checkbox
                v-model="items.active"
                class="custom-control-success mt-50"
                :value="true"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  // BTable,
  // BModal,
  BFormGroup,
  BFormInput,
  // BFormTextarea,
  // BPagination,
  BFormCheckbox,
  BDropdown,
  // BOverlay,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import { VEmojiPicker } from 'v-emoji-picker'

export default {
  components: {
    // BTable,
    // BModal,
    BFormGroup,
    BFormInput,
    BDropdown,
    ValidationObserver,
    // BPagination,
    BFormCheckbox,
    ValidationProvider,
    // BOverlay,
    quillEditor,
    VEmojiPicker,
    // BFormTextarea,
  },
  data() {
    return {
      required,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      question: null,
      answer: null,
      dialogHidden: null,
      stat: '',
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'message...',
      },
      ID: this.$route.params.id,
      active: false,
      items: [],
    }
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    selectEmoji(dataEmoji) { // func insert Emoji เข้า quill
      const cursor = this.editor.getSelection()
      if (cursor) {
        if (cursor.length <= 0) {
          this.editor.insertText(this.editor.getSelection(), dataEmoji.data)
        } else {
          this.editor.setText(dataEmoji.data)
        }
      } else {
        const range = this.editor.getText().length - 1
        this.editor.insertText(range, dataEmoji.data)
      }
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Question/getDataOne', { params })
        this.items = res
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    Submit() {
      if (!this.items.result) {
        this.SwalError(this.$t('key-309'))
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const obj = {
              id: this.ID,
              question: this.items.question,
              result: this.items.result,
              active: this.items.active,
            }
            this.$http.post('/Question/Store/Update', obj)
              .then(() => {
                this.Success(this.$t('key-215'))
                this.$router.push({ name: 'admin-faq' })
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          // alert(this.answer)
          }
        })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}

</script>

<style>

</style>
